<template>
  <ModuleForm
    ref="newsOfTheDayForm"
    :module="newsOfTheDay"
    :module-name="$t('moduleForm.newsOfTheDay')"
    :show-header-info="disabled"
  >
    <template #header-buttons>
      <ModuleFormButton
        v-if="newsOfTheDay.id"
        :title="$t('buttons.publish')"
        :required-permissions="requiredPermissions.publishButton"
        @click="publish"
        :disabled="!!newsOfTheDay.publishedAt"
      />
      <ModuleFormButton
        v-if="disabled"
        :required-permissions="requiredPermissions.editButton"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        :required-permissions="requiredPermissions.saveButton"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="newsOfTheDay.id"
        :record-id="newsOfTheDay.id"
        :required-permissions="requiredPermissions.deleteButton"
        store-name="newsOfTheDay"
        list-router-path="news-of-the-day"
      />
      <ModuleFormButton
        icon="close"
        @click="goToList"
      />
    </template>

    <template #form-content>
      <Preloader v-if="callingAPI" on-top/>
      <div class="row">
        <Input class="col-lg-3"
               @blur="$v.newsOfTheDay.source.$touch()"
               :error="$v.newsOfTheDay.source.$error"
               id="newsOfTheDay_source"
               v-model="newsOfTheDay.source"
               :label="$t('rightNow.source')"
               :disabled="disabled"
               :maxlength="100"
               required
        />
      </div>

      <div class="row">
        <Input
          class="col-lg-3"
          @blur="$v.newsOfTheDay.title.$touch()"
          :error="$v.newsOfTheDay.title.$error"
          id="newsOfTheDay_title"
          v-model="newsOfTheDay.title"
          :label="$t('rightNow.title')"
          :disabled="disabled"
          :maxlength="170"
          required
        />
        <Input
          class="col-lg-7"
          @blur="$v.newsOfTheDay.text.$touch()"
          :error="$v.newsOfTheDay.text.$error"
          id="newsOfTheDay_text"
          v-model="newsOfTheDay.text"
          :label="$t('rightNow.text')"
          :disabled="disabled"
          :maxlength="170"
          required
        />
        <InputCharacterCount
          :title="newsOfTheDay.title"
          :text="newsOfTheDay.text"
          class="col-lg-2"
          label="rightNow.character_count"
          label-from="rightNow.from"
          disabled
        />
      </div>

      <div class="row">
        <Input
          class="col-lg-8"
          @blur="$v.newsOfTheDay.hyperlink.$touch()"
          :error="$v.newsOfTheDay.hyperlink.$error"
          id="newsOfTheDay_hyperlink"
          v-model="newsOfTheDay.hyperlink"
          :label="$t('rightNow.hyperlink')"
          :disabled="disabled"
          input-type="url"
        />
        <Datepicker
          class="col-lg-2"
          type="datetime"
          v-model="newsOfTheDay.publishedAt"
          :label="$t('rightNow.published_at')"
          disabled
        />
        <Datepicker
          class="col-lg-2"
          type="datetime"
          v-model="newsOfTheDay.modifiedAt"
          :label="$t('rightNow.modified_at')"
          disabled
        />
      </div>
    </template>
  </ModuleForm>
</template>

<script>
import NewsOfTheDayModel from '@/model/NewsOfTheDayModel'
import NotifyService from '@/services/NotifyService'
import ModuleForm from '@/components/shared/ModuleForm'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import PermissionService from '@/services/PermissionService'
import Input from '@/components/form/inputs/Input.vue'
import Preloader from '@/components/preloader/Preloader.vue'
import { mapState } from 'vuex'
import Datepicker from '@/components/form/Datepicker'
import { maxLength, required, url } from 'vuelidate/lib/validators'
import InputCharacterCount from '@/views/rightNow/components/InputCharacterCount.vue'

export default {
  name: 'NewsOfTheDayNewView',
  components: {
    InputCharacterCount,
    Datepicker,
    Preloader,
    Input,
    ModuleForm,
    ModuleFormDeleteButton,
    ModuleFormButton
  },
  data () {
    return {
      newsOfTheDay: this._.cloneDeep(NewsOfTheDayModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.RIGHT_NOW_PERMISSIONS,
      characterLimit: 170
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    characterCountValidator () {
      return this.newsOfTheDay.title.length + this.newsOfTheDay.text.length <= this.characterLimit
    }
  },
  methods: {
    async getNewsOfTheDay () {
      await this.$store.dispatch('newsOfTheDay/fetchOne', this.$route.params.id)
      this.newsOfTheDay = this.$store.getters['newsOfTheDay/detail']
    },
    async publish () {
      await this.$store.dispatch('newsOfTheDay/publish', this.newsOfTheDay)
      if (this.$store.getters['newsOfTheDay/error'] === null) {
        NotifyService.setSuccessMessage(this.$t('notify.record_was_published'))
        this.goToList()
      } else {
        NotifyService.setErrorMessage(this.$store.getters['newsOfTheDay/error'])
      }
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      const action = this.newsOfTheDay.id ? 'update' : 'create'
      const successNotify = this.newsOfTheDay.id ? 'record_was_updated' : 'record_was_created'
      await this.$store.dispatch(`newsOfTheDay/${action}`, this.newsOfTheDay)
      if (this.$store.getters['newsOfTheDay/error'] === null) {
        NotifyService.setSuccessMessage(this.$t(`notify.${successNotify}`))
        this.newsOfTheDay.id = this.$store.getters['newsOfTheDay/detail'].id
        this.goToEdit()
      } else {
        NotifyService.setErrorMessage(this.$store.getters['newsOfTheDay/error'])
      }
    },
    goToEdit () {
      this.$router.push('/news-of-the-day/' + this.newsOfTheDay.id + '/edit')
    },
    goToList () {
      this.$router.push('/news-of-the-day/')
    }
  },
  validations () {
    return {
      newsOfTheDay: {
        source: {
          required,
          maxLength: maxLength(100)
        },
        title: {
          required,
          combinedLength: {
            $validator: function () {
              return this.characterCountValidator
            }
          }
        },
        text: {
          required,
          combinedLength: {
            $validator: function () {
              return this.characterCountValidator
            }
          }
        },
        hyperlink: {
          url
        }
      }
    }
  }
}
</script>
